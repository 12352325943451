<template>
  <module
      ref="module"
      id="SetfilesV2"
      titleIcon="fa fa-server"
      title="Setfiles"
      :filters.sync="filters"
      api-url="ACCAPIURL_V4"
      :url="`/setfiles/candidates/${candidateType}`"
      :useDefaultCrud="false"
      :hideEditButton="true"
      :hideDeleteButton="true"
      @on-clean="onClean"
  >
    <template slot="filter-field">
      <div class="form-group">
        <select class="form-control"
                v-model="candidateType"
                @change="filterByGroup">
          <option value="account">Account</option>
          <option value="strategy">Strategy</option>
          <option value="mat">MAT</option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ $t('field') }}</label>
        <select class="form-control"
                v-model="filterCategory.selectedCategory"
                @change="categoryChanged">
          <option v-for="item in filterCategory.categoryList" :label="item.label" :value="item.prop"></option>
        </select>
      </div>
      <div class="form-group">
        <fg-input v-model="filters[filterCategory.selectedCategory]" :label="$t('keyword')" ></fg-input>
      </div>
    </template>
    <template slot="table-columns" v-if="candidateType==='account'">
      <el-table-column :label="$t('action')" align="center" width="100px">
        <template slot-scope="props">
          <el-tooltip content="Generate Setfiles">
            <p-button v-on:click="generateSetfiles(props.row, 'account')" link type="warning">
              <i class="fa fa-refresh"></i>
            </p-button>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column :label="$t('account')" align="left" width="200">
        <template slot-scope="props">
          <span v-on:click="$copyToClipboard(props.row.account)" class="clickable">
            <el-tooltip :content="props.row.broker">
             <div class="logo-broker">
               <img v-if="props.row.logo && props.row.logo != 'null'"
                    :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${props.row.logo}.png`">
               <img v-else alt="Broker">
             </div>
            </el-tooltip>
            <span style="margin-top: 5px">
              {{ props.row.account }}
            </span>
          </span>
          <span>
            <el-tooltip content="Follower">
              <i class="fa fa-users fa-lg" v-if="props.row.type == 'FOLLOWER'" aria-hidden="true"
                 style="color: #51cbce;"></i>
            </el-tooltip>
            <el-tooltip content="Master">
              <i class="fa fa-star fa-lg" v-if="props.row.type == 'MASTER'" aria-hidden="true"
                 style="color: gold;"></i>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('userid')" align="left" width="270">
        <template slot-scope="props">
          <span v-on:click="$copyToClipboard(props.row.userid)" class="clickable">
            {{ props.row.userid }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('servertxt')" align="left">
        <template slot-scope="props">
          <span v-on:click="$copyToClipboard(props.row.server)" class="clickable">
            {{ props.row.server }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('strategytxt')" align="left">
        <template slot-scope="props">
              <span v-on:click="$copyToClipboard(props.row.strategy)" class="clickable">
                {{ props.row.strategy }}
              </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('matServer')" align="left">
        <template slot-scope="props">
          <span v-on:click="$copyToClipboard(props.row.mat_server)" class="clickable">
            {{ props.row.mat_server }}
          </span>
        </template>
      </el-table-column>
    </template>
    <template slot="table-columns" v-if="candidateType==='strategy'">
      <el-table-column :label="$t('action')" align="center" width="100px">
        <template slot-scope="props">
          <el-tooltip content="Generate Setfiles">
            <p-button v-on:click="generateSetfiles(props.row, 'strategy')" link type="warning">
              <i class="fa fa-refresh"></i>
            </p-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$t('strategytxt')" align="left">
        <template slot-scope="props">
          <span style="margin-top: 5px">
            {{ props.row.strat_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('countfollowers')" align="left" width="150px">
        <template slot-scope="props">
          <span style="margin-top: 5px">
              {{ props.row.followers }}
            </span>
        </template>
      </el-table-column>
    </template>
    <template slot="table-columns" v-if="candidateType==='mat'">
      <el-table-column :label="$t('action')" align="center" width="100px">
        <template slot-scope="props">
          <el-tooltip content="Generate Setfiles">
            <p-button v-on:click="generateSetfiles(props.row, 'mat')" link type="warning">
              <i class="fa fa-refresh"></i>
            </p-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$t('matServer')">
        <template slot-scope="props">
           <span style="margin-top: 5px">
            {{ props.row.mat_server }}
          </span>
        </template>
      </el-table-column>
    </template>


  </module>

</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import {Option, Select, Table, TableColumn, Tooltip} from "element-ui";
import {map} from "d3";
import {i18n} from "@/assets/i18n-plugin";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  name: "setfiles2",
  components: {
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      filters: {},
      searchInput: '',
      filterCategory: {
        selectedCategory: 'account',
        categoryList: [
          {prop:'account', category:['account'], label: this.$t('accountNumber')},
          {prop:'email', category:['account'], label: this.$t('email')},
          {prop:'server', category:['account'], label: this.$t('servertxt')},
          {prop:'strategy', category:['account', 'strategy'], label: this.$t('strategytxt')},
          {prop:'mat', category:['account', 'mat'], label: this.$t('matServer')}
        ]
      },
      paramList:[
        {prop:'account', category:['account'], label: this.$t('accountNumber')},
        {prop:'email', category:['account'], label: this.$t('email')},
        {prop:'server', category:['account'], label: this.$t('servertxt')},
        {prop:'strategy', category:['account', 'strategy'], label: this.$t('strategytxt')},
        {prop:'mat', category:['account', 'mat'], label: this.$t('matServer')}
      ],
      candidateType: 'account',
    }
  },
  methods: {
     generateSetfiles(row, type) {
       console.log(row)
       let param = {}
       if(type === 'account') {
         param = {
           "acc_id": row.acc_id,
           "strat_id": row.strat_id,
           "type": row.type,
         }
       }else if (type === 'strategy') {
         param = {
           "strat_id": row.strat_id
         }
       }else if (type === 'mat') {
          param = {
           "mat_server": row.mat_server
         }
       } else {
         return Vue.$toast.warning(i18n.t('ERROR_BADREQUEST'));
       }

      const success = resp => {
        Vue.$toast.success(i18n.t(resp.msg));
      }
      const fail = resp => {
        Vue.$toast.warning(i18n.t('ERROR_BADREQUEST'));
      }

      this.$generateSetfiles(param, type).then(success, fail)
    },
    // mountFilter() {
    //   this.filters[this.filterCategory.selectedCategory] = this.searchInput
    // },
    categoryChanged(){
      this.clearFilter()
    },
    clearFilter(){
      this.filters = {}
    },
    filterByGroup() {
      this.$refs.module.tableLoading = true;
      this.$refs.module.search();

      this.filterCategory.categoryList = this.paramList.filter( el => el.category.includes(this.candidateType))
      this.filterCategory.selectedCategory = this.filterCategory.categoryList[0].prop
      this.clearFilter()
    },
    onClean() {
      this.filterCategory.selectedCategory = 'account'
      this.searchInput = ''
    }
  }

}
</script>


<style scoped lang="scss">
.table-x {
  td, th {
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
  }
}

.logo-broker {
  margin: 0;
  display: inline-block;
}

.logo-broker img {
  border: solid 1px silver;
  border-radius: 100%;
  max-width: 35px;
  max-height: 35px;
}

</style>
